<template>
  <el-dialog title="淘宝查询结果" width="500" :visible.sync="visible" custom-class="v-dialog v-dialog--highLight">
    <table>
      <colgroup>
        <col width="150"/>
        <col width="150"/>
        <col width="200"/>
      </colgroup>
      <thead>
        <tr>
          <th></th>
          <th colspan="3"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
